import React, {Component} from 'react';
import YouTube from 'react-youtube';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './styles/training.scss';
import {getParams} from "../../utils/getParams";
import {getTrainingVideo} from "../../actions/trainings";

class Training extends Component {

  constructor(props) {
    super(props);

    this.state = {
      videoLoading: false,
      videoStatus: false,
      isYoutube: false,
    }

    this.video = null;
  }

  componentDidMount() {
    let params = getParams(window.location);
    this.props.getTrainingVideo(params.id);

    setTimeout(() => {
      document.querySelector('.training').classList.add('_show');
    }, 200)
  }


  componentDidUpdate(prevProps) {
    if (!prevProps.training && this.props.training) {
      this.setState({
        isYoutube: !this.props.training.video_url && !!this.props.training.youtube_video_id
      });
    }
  };

  // write video element in variables
  readyVideo(e) {
    this.video = e.target;

    this.setState({
      videoStatus: false,
      videoLoading: true
    })
  }

  // play video (hide preview)
  playVideo() {
    if(this.state.isYoutube)
      this.video.playVideo();
    else
      this.video.play();

    this.setState({
      videoStatus: true
    })
  }

  // stop video (show preview)
  endVideo() {
    this.video.stopVideo();
    
    this.setState({
        videoStatus: false
    })
  }

  render() {
    const opts = {
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        // autoplay: 1,
        rel: 0,
        showinfo: 0
      }
    };

    return(
      <div className="training">
        <div className="training__content">
          {
            this.props.training &&
            <div className="training__item">
              <div className={`training__item__preview ${this.state.videoStatus ? '_hidden' : ''}`}
                   style={{
                     backgroundImage: this.props.training.video_image_url
                         ? `url(${this.props.training.video_image_url})`
                         : 'none'
                   }}>
                {
                  this.state.videoLoading && <button type="button" onClick={this.playVideo.bind(this)}></button>
                }
              </div>
              <div className="training__item__video">
                {
                  this.state.isYoutube ?
                      <YouTube
                          videoId={this.props.training.youtube_video_id}
                          opts={opts}
                          onReady={this.readyVideo.bind(this)}
                          onEnd={this.endVideo.bind(this)}
                      />
                      : <video playsInline
                               controls
                               onEnded={this.endVideo.bind(this)}
                               onCanPlay={this.readyVideo.bind(this)}
                      >
                        <source src={this.props.training.video_url} type="video/mp4"/>
                      </video>
                }

              </div>
              {
                this.props.training.name &&
                <div className="training__item__title">
                  <p>{this.props.training.name}</p>
                </div>
              }
            </div>
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    training: state.trainings.training,
  }
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getTrainingVideo,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Training)