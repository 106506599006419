import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { changeStep, setGoal, setPlace, setCount, setMuscleGroup } from '../../actions/processing';

import './styles/stepItem.scss';

const mobileMax = 699;
const tabletPortraitMax = 899;
const tabletLandscapeMax = 1199;

class StepItem extends Component {

  constructor(props) {
    super(props);

    this.state = {
      answerHoverId: null,
      
      answerID: null,
      answerValue: '',
    }
  }

  answerHoverHadler(value) {
    this.setState({
      answerHoverId: value
    })
  }

  setAnswer(id, value) {
    if (this.state.answerID !== id) {
      this.setState({
        answerID: id,
        answerValue: value
      })
    } else if (this.state.answerID === id && window.innerWidth < tabletLandscapeMax) {
      this.setState({
        answerID: null,
        answerValue: '',
      })
    }
  }

  nextScreen() {
    if (this.props.step === 2) {
      this.props.setGoal(this.state.answerID, this.state.answerValue);
      this.props.changeStep(3);
    } else if (this.props.step === 3) {
      //this.props.setPlace(this.state.answerID, this.state.answerValue);
      this.props.setMuscleGroup(this.state.answerID, this.state.answerValue);
      this.props.changeStep(4);
    } else if (this.props.step === 4) {
      this.props.setCount(this.state.answerID, this.state.answerValue);
      this.props.history.push('/finish');
    }

    if (this.props.step !== 4 && window.innerWidth < mobileMax) {
      window.scrollTo(0, 0);
    }
  }

  render() {

    const {
      step,
      dataStep,
      gender
    } = this.props;

    let strDesc = null;

    if (this.state.answerHoverId && window.innerWidth > tabletPortraitMax) {
      dataStep.answers.forEach(el => {
        if (el.id === this.state.answerHoverId) {
          strDesc = el.desc;
        }
      })
    } else if (this.state.answerID && window.innerWidth > tabletPortraitMax) {
      dataStep.answers.forEach(el => {
        if (el.id === this.state.answerID) {
          strDesc = el.desc;
        }
      })
    } else {
      strDesc =dataStep.question_desc;
    }

    // cut answers count
    if (dataStep.step === 4) {
      dataStep.answers.length = 3;
    }

    return(
      <div className={`process__item step ${step === dataStep.step && '_activeStep'}`}>
        <div className={`step__content ${!dataStep?.images?.length && '_two'}`}>

          <div className="step__question">
            <h2>{dataStep.question_title}</h2>
            {/* { dataStep.question_title_desc && <p className="title-desc">{dataStep.question_title_desc}</p> } */}
            <p>{strDesc}</p>
          </div>

          <div className="step__main">
            {
              dataStep.images && dataStep.images.length
              ?  <div className="step__images">
                  {
                    dataStep.images.map((el, ind) => (
                      <div
                        key={ind}
                        className={`
                          step__images__item
                          ${this.state.answerID === el.id && !this.state.answerHoverId && '_selected'}
                          ${this.state.answerHoverId === el.id && '_show'}
                        `}
                        style={{backgroundImage: `url(${require(`../../static/images/question/${gender}/${el.image}`)})`}}
                      />
                    ))
                  }
                </div>
              : null
            }

            {
              dataStep.answers && dataStep.answers.length
              ? <div className="step__answers">
                  {
                    dataStep.answers && dataStep.answers.map((el, ind) => (
                      <div
                        key={ind}
                        className={`step__answers__item _type-${dataStep.answers.length} ${this.state.answerID === el.id && '_active'}`}
                        onMouseEnter={() => this.answerHoverHadler(el.id)}
                        onMouseLeave={() => this.answerHoverHadler(null)}
                        onClick={ () => this.setAnswer(el.id, el.title) }
                      >
                        <span>{el.title}</span>
                        {el.subtitle && <p className="answer-desc">{el.subtitle}</p>}
                        { 
                          dataStep.step === 2 && dataStep.images && dataStep.images.length
                          ? <div className="mobile-img" style={{backgroundImage: `url(${require(`../../static/images/question/${gender}/${dataStep.images[ind].mobile}`)})`}}></div>
                          : null
                        }
                      </div>
                    ))
                  }
                </div>
              : null
            }
          </div>

          <div className={`step__arrow ${(this.state.answerID || this.state.answerHoverId) && '_hide'}`}>
            <img src={ require('../../static/images/icons/step-arrow.svg') } alt=""/>
          </div>

          <div className={`step__next ${this.state.answerID && '_show'}`}>
            <div className="container">
              <div className="step__next__counter">
                <span>{`0${step} / 04`}</span>
              </div>
            </div>
            <div className="step__next__btn">
              <button
                type="button"
                className={`btn btn__type-4 ${!this.state.answerID && '_disabled'}`}
                onClick={ () => this.nextScreen() }
              >
                <span>Далее</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    processing
  } = state;

  return {
    ...processing
  }
};

const mapDispatchToProps = dispatch => bindActionCreators({
  changeStep,
  setGoal,
  setPlace,
  setMuscleGroup,
  setCount
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StepItem)
